import React from "react";
import { Tooltip } from "antd";

type Props = {
  showTitle?: boolean;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
};

const DownloadExcelIcon = ({ showTitle = true, onClick }: Props) => {
  return (
    <Tooltip title={showTitle ? "Descargar Excel" : undefined}>
      <img
        src="/assets/archivo-excel.png"
        alt="Descargar Excel"
        style={{
          cursor: "pointer",
          width: "20px",
          height: "20px",
          display: "inline-block"
        }}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default DownloadExcelIcon;
