import { observer } from "mobx-react-lite";
import ReactECharts from "echarts-for-react";
import { Button, Col, Form, Row, Spin, Table, Tabs, Typography } from "antd";
import {
  getDefaultColumnProps,
  IColumns,
} from "../../../app/common/table/utils";
import { useEffect, useRef, useState } from "react";
import { toJS } from "mobx";
import { useStore } from "../../../app/stores/store";
import { title } from "process";
import { formItemLayout } from "../../../app/util/utils";
import DateRangeInput from "../../../app/common/form/proposal/DateRangeInput";
import moment from "moment";

const { Text, Title } = Typography;
type Information = {
  parameterHistory: ParameterHistoryInfo;
};
type ParameterHistoryInfo = {
  paciente: string;
  solicitud: string;
  claveParametro: string;
  nombreParametro: string;
  solicitudId: string;
  parametroId: string;
  estudioId: number;
  valorInicial: string;
  valorFinal: string;
  isAllHistory: boolean;
  expediente: string;
  expedienteId: string;
};

const ClinicalResultsHistory = ({ parameterHistory }: Information) => {
  const { isAllHistory } = parameterHistory;
  const { clinicResultsStore } = useStore();
  const { getHistoryResults, getHistoryResultsByRecord } = clinicResultsStore;
  const [history, setHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  console.log("parameterHistory", toJS(parameterHistory));
  const columns: IColumns<any> = [
    {
      ...getDefaultColumnProps("resultado", "Resultado", {
        width: "15%",
      }),
      render: (text, record) => {
        let fieldRange =
          parseFloat(parameterHistory.valorInicial) > parseFloat(text ?? 0) ||
          parseFloat(text ?? 0) > parseFloat(parameterHistory.valorFinal);
        if (
          parameterHistory.valorInicial?.includes("<") ||
          parameterHistory.valorInicial?.includes(">")
        ) {
          let operator = parameterHistory.valorInicial?.includes("<")
            ? "<"
            : ">";
          fieldRange = !(operator === "<"
            ? parseFloat(text.trim() ?? 0) <
              parseFloat(parameterHistory.valorInicial?.replace("<", "").trim())
            : parseFloat(text.trim() ?? 0) >
              parseFloat(
                parameterHistory.valorInicial?.replace(">", "").trim()
              ));
        }
        return (
          <Text style={{ color: fieldRange ? "red" : "black" }}>{text}</Text>
        );
      },
    },
    {
      ...getDefaultColumnProps("unidades", "Unidades", {
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("usuario", "Usuario", {
        width: "15",
      }),
    },
    {
      ...getDefaultColumnProps("fecha", "Fecha y Hora", {
        width: "15",
      }),
    },
  ];
  const [originalHistory, setOriginalHistory] = useState<any[]>([]);
  useEffect(() => {
    setLoading(true);
    const getHistory = async () => {
      let historyResult = [];
      if (isAllHistory) {
        historyResult = await getHistoryResultsByRecord(
          parameterHistory.expedienteId,
          parameterHistory.parametroId,
          [moment(), moment()]
        );
      } else {
        historyResult = await getHistoryResults(
          parameterHistory.solicitudId,
          parameterHistory.estudioId,
          parameterHistory.parametroId
        );
      }

      setOriginalHistory(historyResult);
      setHistory(historyResult);
    };
    getHistory();
    setLoading(false);
  }, []);
  const [seriesX, setSeriesX] = useState<string[]>([]);
  const [seriesY, setSeriesY] = useState<number[]>([]);
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [unidades, setUnidades] = useState<string>("");

  useEffect(() => {
    let fechas = history.map((x) => x.fecha);
    let valores = history.map((x) => +x.resultado).reverse();
    setSeriesX(fechas.reverse());
    setSeriesY(valores);
    let valorfinal = parseFloat(parameterHistory.valorFinal) ?? 0;
    let valorInicial = parameterHistory.valorInicial?.includes("<")
      ? parseFloat(parameterHistory.valorInicial?.split("<")[1].trim()) ?? 0
      : parameterHistory.valorInicial?.includes(">")
      ? parseFloat(parameterHistory.valorInicial?.split(">")[1].trim()) ?? 0
      : parseFloat(parameterHistory.valorInicial) ?? 0;
    let minus = Math.min(...valores);
    let max = Math.max(...valores);
    setMin(minus < valorInicial ? minus : valorInicial);
    setMax(max > valorfinal ? max : valorfinal);
    setUnidades(history[0]?.unidades ?? "");
  }, [history]);

  const options = {
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      width: "auto",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis", // Muestra la información al pasar sobre un eje (x o y)
      axisPointer: {
        type: "cross", // Línea cruzada para señalar puntos en ambos ejes
      },
      formatter: (params: any) => {
        const dataPoint = params[0];
        const value = dataPoint.value;
        const date = dataPoint.axisValue;

        return `
        <div>
          <strong>Fecha:</strong> ${date}<br />
          <strong>Valor:</strong> ${value} ${unidades}<br />
          <strong>Parámetro:</strong> ${parameterHistory.nombreParametro}
        </div>
      `;
      },
    },
    xAxis: {
      type: "category",

      data: seriesX,
    },
    yAxis: {
      type: "value",
      min: min,
      max: max,
      splitLine: {
        // show: false,
      },
    },
    series: [
      {
        data: seriesY,
        type: "line",
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => {
            const value = params.value;
            let isOutOfRange = false;
            if (
              parameterHistory.valorInicial?.includes("<") ||
              parameterHistory.valorInicial?.includes(">")
            ) {
              const valWithousSimbols = parameterHistory.valorInicial.includes(
                "<"
              )
                ? parseFloat(parameterHistory.valorInicial.split("<")[1].trim())
                : parameterHistory.valorInicial.includes(">")
                ? parseFloat(parameterHistory.valorInicial.split(">")[1].trim())
                : parseFloat(parameterHistory.valorInicial);
              isOutOfRange = parameterHistory.valorInicial.includes("<")
                ? !(value < valWithousSimbols)
                : !(value > valWithousSimbols);
              console.log("isOutOfRange", isOutOfRange);
              console.log("value", value);
              console.log("valWithousSimbols", valWithousSimbols);
            } else {
              isOutOfRange =
                value < parseFloat(parameterHistory.valorInicial) ||
                value > parseFloat(parameterHistory.valorFinal);
            }
            return `{${isOutOfRange ? "red" : "black"}|${value} ${unidades}}`;
          },
          rich: {
            red: {
              color: "red",
            },
            black: {
              color: "black",
            },
          },
        },
        markLine: {
          data: [
            {
              yAxis: parseFloat(parameterHistory.valorFinal) ?? 0,
              label: {
                formatter: parameterHistory.valorFinal ?? "",
              },
            },
            {
              yAxis: parameterHistory.valorInicial.includes("<")
                ? parseFloat(
                    parameterHistory.valorInicial.split("<")[1].trim()
                  ) ?? 0
                : parameterHistory.valorInicial.includes(">")
                ? parseFloat(parameterHistory.valorInicial.split(">")[1].trim())
                : parseFloat(parameterHistory.valorInicial) ?? 0,
              label: {
                formatter: parameterHistory.valorInicial ?? "",
              },
            },
          ],
          lineStyle: {
            type: "solid",
            color: "gray",
          },
        },
      },
    ],
    toolbox: {
      feature: {
        saveAsImage: {
          title: "Descargar",
          name: `Historial de ${parameterHistory.nombreParametro}`,
        },
      },
    },
    graphic: [
      {
        type: "text",
        left: "left",
        top: "10",
        style: {
          text: "Paciente: " + parameterHistory.paciente,
          fontSize: 12,
          fontWeight: "bold",
          fill: "black",
        },
      },
      {
        type: "text",
        left: "left",
        top: "20",
        style: {
          text: "Expediente: " + parameterHistory.expediente,
          fontSize: 12,
          fontWeight: "bold",
          fill: "black",
        },
      },
      {
        type: "text",
        left: "left",
        top: "30",
        style: {
          text: "Nombre del parametro: " + parameterHistory.nombreParametro,
          fontSize: 12,
          fontWeight: "bold",
          fill: "black",
        },
      },
    ],
  };

  // const tabs = useRef([
  //   {
  //     key: "general",
  //     label: "Tabla",
  //     children: <Table columns={columns} dataSource={history}></Table>,
  //   },
  // ]);
  let tabs = [
    {
      key: "general",
      label: "Tabla",
      children: <Table columns={columns} dataSource={history}></Table>,
    },
    {
      key: "studies",
      label: "Gráfica",
      children: (
        <div
          style={{
            border: "1px solid black",
            borderRadius: "8px",
            padding: "5px",
          }}
        >
          <ReactECharts option={options} style={{ height: 500 }} notMerge />
        </div>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={loading}>
        <Row>
          <Col span={3}>
            <Text strong>Paciente:</Text>
          </Col>
          <Col>
            <Text>{parameterHistory.paciente}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <Text strong>{isAllHistory ? "Expediente: " : "Solicitud: "}:</Text>
          </Col>
          <Col>
            <Text>
              {isAllHistory
                ? parameterHistory.expediente
                : parameterHistory.solicitud}
            </Text>
          </Col>
        </Row>
        <Row style={{ paddingTop: 10 }}>
          <Col span={6}>
            <Text strong>Nombre parámetro:</Text>
          </Col>
          <Col>
            <Text>
              {parameterHistory.nombreParametro} (
              {parameterHistory.claveParametro})
            </Text>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Form
              {...formItemLayout}
              form={form}
              onFinish={async () => {
                setLoading(true);
                console.log("onFinish");
                const values = form.getFieldValue("fechas");
                // console.log("values", values);
                // filterHistoryByDate(values);
                let historyResult = await getHistoryResultsByRecord(
                  parameterHistory.expedienteId,
                  parameterHistory.parametroId,
                  values
                );
                setHistory(historyResult);
                setLoading(false);
              }}
              size="small"
              initialValues={{
                fechas: [moment(), moment()],
              }}
            >
              {isAllHistory && (
                <>
                  <Row gutter={[12, 12]}>
                    <Col span={16}>
                      <DateRangeInput
                        formProps={{ name: "fechas", label: "Fecha" }}
                      ></DateRangeInput>
                    </Col>
                    <Col span={8}>
                      <Button type="primary" htmlType="submit">
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              items={
                isAllHistory ? tabs : tabs.filter((x) => x.key === "general")
              }
            ></Tabs>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default observer(ClinicalResultsHistory);
