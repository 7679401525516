import { makeAutoObservable } from "mobx";

import { InvoiceRequestCompanyType } from "../../components/invoice/invoice/utils/utils";
import InvoiceRequestCompany from "../api/invoiceRequestCompany";
import { IInvoice } from "../models/invoice";
import {
  IInvoiceRequestCompanyFilter,
  IInvoiceRequestCompanyList,
  InvoiceRequestCompanyFilterValues,
} from "../models/invoiceCompany";
import alerts from "../util/alerts";
import { getErrors } from "../util/utils";
import { store } from "./store";
import { IScopes, Scopes } from "../models/shared";

export default class InvoiceRequestCompanyStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  filter: IInvoiceRequestCompanyFilter =
    new InvoiceRequestCompanyFilterValues();
  invoices: IInvoiceRequestCompanyList[] = [];
  creatingCompanyInvoices: boolean = false;

  setFilter = (filter: IInvoiceRequestCompanyFilter) => {
    this.filter = filter;
  };

  setInvoices = (invoices: IInvoiceRequestCompanyList[]) => {
    this.invoices = invoices;
  };

  getAll = async (
    type: InvoiceRequestCompanyType,
    filter: IInvoiceRequestCompanyFilter
  ) => {
    try {
      const invoices = await InvoiceRequestCompany.getAll(type, filter);
      return invoices;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  getDetailByRequests = async (
    type: InvoiceRequestCompanyType,
    requestsIds: string[]
  ) => {
    try {
      const detail = await InvoiceRequestCompany.getDetailByRequests(
        type,
        requestsIds
      );
      return detail;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  create = async (type: InvoiceRequestCompanyType, invoice: IInvoice) => {
    try {
      store.invoiceStore.setLoadingDetail(true);
      const id = await InvoiceRequestCompany.create(type, invoice);
      alerts.success("Factura creada correctamente");
      return id;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      store.invoiceStore.setLoadingDetail(false);
    }
  };

  cancel = (invoiceId: string) => {
    this.invoices = this.invoices.map((x) => ({
      ...x,
      // facturas: x.facturas.map((i) => {
      //   return {
      //     ...i,
      //     estatus:
      //       i.facturaId === invoiceId ? status.invoice.cancelado : i.estatus,
      //   };
      // }),
    }));
  };

  printTicket = async (requestsIds: string[]) => {
    try {
      await InvoiceRequestCompany.printTicket("request", requestsIds);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  downloadList = async (
    type: InvoiceRequestCompanyType,
    filter: IInvoiceRequestCompanyFilter
  ) => {
    try {
      await InvoiceRequestCompany.downloadList(type, filter);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
