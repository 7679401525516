import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  InputRef,
  PageHeader,
  Pagination,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { v4 as uuid } from "uuid";

import { CopyOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";

import IconButton from "../../../app/common/button/IconButton";
import ImageButton from "../../../app/common/button/ImageButton";
import NumberInput from "../../../app/common/form/proposal/NumberInput";
import TextAreaInput from "../../../app/common/form/proposal/TextAreaInput";
import SelectInput from "../../../app/common/form/SelectInput";
import SwitchInput from "../../../app/common/form/SwitchInput";
import TextInput from "../../../app/common/form/TextInput";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import { IIndicationList } from "../../../app/models/indication";
import { IPacketList } from "../../../app/models/packet";
import { IParameterList } from "../../../app/models/parameter";
import {
  IStudyForm,
  IStudyTag,
  StudyFormValues,
} from "../../../app/models/study";
import { useStore } from "../../../app/stores/store";
import alerts from "../../../app/util/alerts";
import messages from "../../../app/util/messages";
import { formItemLayout } from "../../../app/util/utils";
import views from "../../../app/util/view";
import useWindowDimensions, { resizeWidth } from "../../../app/util/window";
import { IndicationModal } from "./modals/IndicationModal";
import { ParameterModal } from "./modals/ParameterModal";
import { TagStudyModal } from "./modals/TagStudyModal";

import type { SortableContainerProps, SortEnd } from "react-sortable-hoc";

const { Link } = Typography;

type StudyFormProps = {
  componentRef: React.MutableRefObject<any>;
  load: boolean;
};
type UrlParams = {
  id: string;
};

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

const StudyForm: FC<StudyFormProps> = ({ componentRef, load }) => {
  const { optionStore, studyStore } = useStore();
  const {
    getDepartmentOptions,
    departmentOptions,
    getAreaOptions,
    areaOptions,
    getPrintFormatsOptions,
    getMaquiladorOptions,
    MaquiladorOptions,
    getMethodOptions,
    MethodOptions,
    getsampleTypeOptions,
    sampleTypeOptions,
    getworkListOptions2,
    getParameterOptions,
    getIndication,
    getReagentOptions,
  } = optionStore;
  const [selectedRowKeysp, setSelectedRowKeysp] = useState<React.Key[]>([]);
  const [selectedRowKeysi, setSelectedRowKeysi] = useState<React.Key[]>([]);
  const [selectedRowKeyTags, setSelectedRowKeyTags] = useState<React.Key[]>([]);

  const {
    getById,
    study,
    update,
    create,
    parameterSelected,
    setParameterSelected,
    indicationSelected,
    setIndicationSelected,
    tagsSelected,
    setTagsSelected,
    scopes,
  } = studyStore;
  const [form] = Form.useForm<IStudyForm>();
  const { width: windowWidth } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const [values, setValues] = useState<IStudyForm>(new StudyFormValues());
  const [searchParams] = useSearchParams();
  const [visible, setVisible] = useState<boolean>(false);
  let { id } = useParams<UrlParams>();

  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");

  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  const [parameterSelectedSource, setParameterSelectedSource] =
    useState(parameterSelected);

  const departmentId = Form.useWatch("departamento", form);

  useEffect(() => {
    setParameterSelectedSource(
      parameterSelected.map((x, i) => ({ ...x, index: i }))
    );
  }, [parameterSelected]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  useEffect(() => {
    form.setFieldValue("activo", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const readStudy = async (id: number) => {
      setLoading(true);
      const study = await getById(id);

      if (study?.workLists !== "" && study?.workLists != null) {
        var tags = study.workLists.split("-");
        setTags(tags);
      }

      if (study) {
        setParameterSelected(study.parameters);
        setIndicationSelected(study.indicaciones);
        setTagsSelected(study.etiquetas);

        form.setFieldsValue(study);
        setValues(study);
      }

      setLoading(false);
    };
    if (id) {
      readStudy(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, getById, id]);

  useEffect(() => {
    getMaquiladorOptions();
    getDepartmentOptions();
    getPrintFormatsOptions();
    getMethodOptions();
    getsampleTypeOptions();
    getworkListOptions2();
    getParameterOptions();
    getIndication();
    getReagentOptions();
  }, [
    getMaquiladorOptions,
    getDepartmentOptions,
    getPrintFormatsOptions,
    getMethodOptions,
    getsampleTypeOptions,
    getworkListOptions2,
    getParameterOptions,
    getIndication,
    getReagentOptions,
  ]);

  useEffect(() => {
    if (departmentId) getAreaOptions(departmentId);
  }, [departmentId, getAreaOptions]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const [disabled, setDisabled] = useState(() => {
    let result = false;
    const mode = searchParams.get("mode");
    if (mode === "ReadOnly") {
      result = true;
    }
    return result;
  });

  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const columnsParameter: IColumns<IParameterList> = [
    {
      ...getDefaultColumnProps("nombre", "Parámetro", {
        searchState,
        setSearchState,
        width: "40%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "40%",
        windowSize: windowWidth,
      }),
      render: (value: any, row: any) => {
        return (
          <>
            <Link
              onClick={() => {
                navigate(
                  `/parameters/${row?.id}?mode=${searchParams.get(
                    "mode"
                  )}&search=${searchParams.get("search") ?? "all"}`
                );
              }}
            >
              {value}
            </Link>
          </>
        );
      },
    },
    {
      title: "Aparición",
      dataIndex: "i",
      width: "10%",
      render: (_value, _item, i) => i + 1,
    },
    {
      title: "Orden",
      dataIndex: "sort",
      width: "10%",
      className: "drag-visible",
      render: () => !disabled && <DragHandle />,
    },
  ];

  const columnsIndication: IColumns<IIndicationList> = [
    {
      ...getDefaultColumnProps("nombre", "Indicación", {
        searchState,
        setSearchState,
        width: "45%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "45%",
        windowSize: windowWidth,
      }),
    },
  ];

  const columnsTags: IColumns<IStudyTag> = [
    {
      ...getDefaultColumnProps("claveEtiqueta", "Clave etiqueta", {
        searchState,
        setSearchState,
        width: "20%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("nombreEtiqueta", "Nombre etiqueta", {
        searchState,
        setSearchState,
        width: "40%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("nombreEstudio", "Nombre", {
        searchState,
        setSearchState,
        width: "30%",
        windowSize: windowWidth,
      }),
      render: (_, tag) => (
        <Input
          value={tag.nombreEstudio}
          onChange={(e) => {
            const newData = [...tagsSelected];
            const targetIndex = newData.findIndex((item) => item.id === tag.id);
            if (targetIndex !== -1) {
              newData[targetIndex].nombreEstudio = e.target.value;
              setTagsSelected(newData);
            }
          }}
        />
      ),
    },
    {
      title: "Cant.",
      dataIndex: "cantidad",
      width: "10%",
      align: "center",
      render: (cant, tag) =>
        (scopes.crear || scopes.modificar) && (
          <InputNumber
            value={cant}
            min={0}
            max={20}
            onChange={(value) => {
              const newTags = tagsSelected.map((x) => {
                if (x.id === tag.id) {
                  x.cantidad = value;
                }
                return x;
              });
              setTagsSelected(newTags);
              console.log("value", value);
            }}
          ></InputNumber>
        ),
    },
    {
      title: "Duplicar",
      dataIndex: "duplicar",
      width: "10%",
      align: "center",
      render: (_, tag) =>
        (scopes.crear || scopes.modificar) && (
          <IconButton
            title="Duplicar fila"
            icon={<CopyOutlined />}
            onClick={() => {
              onDuplicateTag(tag);
            }}
          />
        ),
    },
  ];

  const actualStudy = () => {
    if (id) {
      const index = study.findIndex((x) => x.id === Number(id));
      return index + 1;
    }
    return 0;
  };
  const siguienteStudy = (index: number) => {
    const estudio = study[index];

    navigate(
      `/${views.study}/${estudio?.id}?mode=${searchParams.get("mode")}&search=${
        searchParams.get("search") ?? "all"
      }`
    );
  };

  const onFinish = async (newValues: IStudyForm) => {
    setLoading(true);
    const study = { ...values, ...newValues };
    study.parameters = [...parameterSelectedSource];
    study.indicaciones = [...indicationSelected];
    study.etiquetas = [
      ...tagsSelected.map((x, i) => {
        if (typeof x.id === "string") {
          x.id = -(i + 1);
        }
        return x;
      }),
    ];

    let success = false;
    var worklist = "";
    for (var i = 0; i < tags.length; i++) {
      if (i === 0) {
        worklist += tags[i];
      } else {
        worklist += `-${tags[i]}`;
      }
    }
    study.workLists = worklist;
    if (!study.id) {
      success = await create(study);
    } else {
      success = await update(study);
    }

    if (success) {
      navigate(`/${views.study}?search=${searchParams.get("search") || "all"}`);
    }
    setLoading(false);
  };

  const onValuesChange = async (changeValues: any) => {
    const fields = Object.keys(changeValues)[0];
    if (fields === "diasrespuesta") {
      const value = changeValues[fields];
      if (value == null) {
        form.setFieldsValue({ tiemporespuesta: undefined });
      } else {
        let horas = value * 24;
        horas = Math.round(horas * 100) / 100;
        form.setFieldsValue({ tiemporespuesta: horas });
      }
    }
    if (fields === "tiemporespuesta") {
      const value = changeValues[fields];
      if (value == null) {
        form.setFieldsValue({ diasrespuesta: undefined });
      } else {
        let dias = value / 24;
        if (dias < 1) {
          dias = 0;
        } else {
          dias = Math.round(dias * 100) / 100;
        }
        form.setFieldsValue({ diasrespuesta: dias });
      }
    }
  };

  const deleteParameter = () => {
    const filterList = parameterSelectedSource.filter(
      (x) => !selectedRowKeysp.includes(x.index!)
    );
    setParameterSelectedSource(filterList);
    setSelectedRowKeysp([]);
  };

  const deleteIndicacion = () => {
    const filterList = indicationSelected.filter(
      (x) => !selectedRowKeysi.includes(x.id)
    );
    setIndicationSelected(filterList);
    setSelectedRowKeysi([]);
  };

  const deleteTag = () => {
    const filterList = tagsSelected.filter(
      (x) => !selectedRowKeyTags.includes(x.id!)
    );
    setTagsSelected(filterList);
    setSelectedRowKeyTags([]);
  };

  const onSelectChangep = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeysp(newSelectedRowKeys);
  };
  const rowSelectionp = {
    selectedRowKeysp,
    onChange: onSelectChangep,
    getCheckboxProps: () => ({
      disabled: disabled,
    }),
  };

  const onSelectChangei = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeysi(newSelectedRowKeys);
  };
  const rowSelectioni = {
    selectedRowKeysi,
    onChange: onSelectChangei,
    getCheckboxProps: () => ({
      disabled: disabled,
    }),
  };

  const onSelectChanget = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeyTags(newSelectedRowKeys);
  };
  const rowSelectiont = {
    selectedRowKeyst: selectedRowKeyTags,
    onChange: onSelectChanget,
    getCheckboxProps: () => ({
      disabled: disabled,
    }),
  };
  const columns: IColumns<IPacketList> = [
    {
      ...getDefaultColumnProps("id", "Id", {
        searchState,
        setSearchState,
        width: "30%",
        windowSize: windowWidth,
      }),
    },
    {
      ...getDefaultColumnProps("nombre", "Paquete", {
        searchState,
        setSearchState,
        width: "30%",
        windowSize: windowWidth,
      }),
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        parameterSelectedSource.slice(),
        oldIndex,
        newIndex
      ).filter((el: IParameterList) => !!el);
      setParameterSelectedSource(newData);
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = parameterSelectedSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onSearchTags = async () => {
    const selectedTags = await TagStudyModal();
    setTagsSelected([
      ...tagsSelected,
      ...selectedTags.map((x) => {
        const newTags: IStudyTag = {
          id: uuid(),
          etiquetaId: x.id,
          claveEtiqueta: x.clave,
          nombreEtiqueta: x.nombre,
          claveInicial: x.claveInicial,
          nombreEstudio: form.getFieldValue("clave"),
          cantidad: x.cantidad,
          claveEstudio: form.getFieldValue("clave"),
          color: "",
          destino: "",
          destinoId: "",
          destinoTipo: 0,
          estudioId: 0,
          orden: 0,
        };
        return newTags;
      }),
    ]);
  };

  const onDuplicateTag = (x: IStudyTag) => {
    setTagsSelected([
      ...tagsSelected,
      {
        id: uuid(),
        etiquetaId: x.etiquetaId,
        claveEtiqueta: x.claveEtiqueta,
        nombreEtiqueta: x.nombreEtiqueta,
        claveInicial: x.claveInicial,
        nombreEstudio: form.getFieldValue("clave"),
        cantidad: x.cantidad,
        claveEstudio: form.getFieldValue("clave"),
        color: "",
        destino: "",
        destinoId: "",
        destinoTipo: 0,
        estudioId: 0,
        orden: 0,
      },
    ]);
  };

  return (
    <Spin spinning={loading || load}>
      <Row style={{ marginBottom: 24 }}>
        <Col md={12} sm={24} xs={12} style={{ textAlign: "left" }}>
          {id && (
            <Pagination
              simple
              key="pagination"
              size="small"
              total={study.length}
              pageSize={1}
              current={actualStudy()}
              onChange={(value) => {
                siguienteStudy(value - 1);
              }}
              showSizeChanger={false}
            />
          )}
        </Col>
        {!disabled && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                navigate(`/${views.study}`);
              }}
            >
              Cancelar
            </Button>
            {(scopes.crear || scopes.modificar) && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  form.submit();
                }}
              >
                Guardar
              </Button>
            )}
          </Col>
        )}
        {disabled && scopes.modificar && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "right" }}>
            <ImageButton
              key="edit"
              title="Editar"
              image="editar"
              onClick={() => {
                setDisabled(false);
                navigate(
                  `/${views.study}/${id}?mode=edit&search=${
                    searchParams.get("search") ?? "all"
                  }`
                );
              }}
            />
          </Col>
        )}
      </Row>
      <div style={{ display: load ? "none" : "" }}>
        <div ref={componentRef}>
          {load && (
            <PageHeader
              ghost={false}
              title={<HeaderTitle title="Catálogo Estudios" image="estudio" />}
              className="header-container"
            ></PageHeader>
          )}
          {load && <Divider className="header-divider" />}
          <Form<IStudyForm>
            {...formItemLayout}
            form={form}
            name="study"
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row gutter={[30, 12]}>
              <Col md={8} sm={24} xs={8}>
                <TextInput
                  formProps={{
                    name: "clave",
                    label: "Clave",
                  }}
                  max={100}
                  required
                  readonly={disabled}
                />
              </Col>
              <Col md={8} sm={24} xs={8}>
                <SelectInput
                  formProps={{ name: "departamento", label: "Departamento" }}
                  options={departmentOptions.filter((x) => x.value !== 1)}
                  readonly={disabled}
                  required
                />
              </Col>
              <Col md={8} sm={24} xs={8}>
                <NumberInput
                  formProps={{
                    name: "orden",
                    label: "Orden",
                  }}
                  max={99999999}
                  min={0}
                  required
                  readonly={disabled}
                />
              </Col>
              <Col md={8} sm={24} xs={8}>
                <SelectInput
                  formProps={{ name: "area", label: "Área" }}
                  options={!departmentId ? [] : areaOptions}
                  readonly={disabled}
                  required
                />
              </Col>
              <Col md={8} sm={24} xs={8}>
                <NumberInput
                  formProps={{
                    name: "cantidad",
                    label: "Cantidad",
                  }}
                  min={1}
                  max={999999}
                  readonly={disabled}
                  required
                />
              </Col>
              <Col md={8} sm={24} xs={8}>
                <TextInput
                  formProps={{
                    name: "nombre",
                    label: "Nombre",
                  }}
                  max={100}
                  required
                  readonly={disabled}
                />
              </Col>
              <Col md={8} sm={24} xs={8}>
                <SelectInput
                  formProps={{ name: "maquilador", label: "Maquilador" }}
                  options={MaquiladorOptions}
                  readonly={disabled}
                />
              </Col>
              <Col md={8} sm={24} xs={8}>
                <SelectInput
                  formProps={{ name: "tipomuestra", label: "Tipo de muestra" }}
                  options={sampleTypeOptions}
                  readonly={disabled}
                />
              </Col>
              <Col md={8} sm={24} xs={8}>
                <TextInput
                  formProps={{
                    name: "titulo",
                    label: "Titulo",
                  }}
                  max={100}
                  readonly={disabled}
                />
              </Col>
              <Col md={8} sm={24} xs={8}>
                <SelectInput
                  formProps={{ name: "metodo", label: "Método" }}
                  options={MethodOptions}
                  readonly={disabled}
                />
              </Col>
              <Col md={8} xs={8}>
                <NumberInput
                  formProps={{
                    name: "diasrespuesta",
                    label: "Días de respuesta",
                  }}
                  min={0}
                  max={999999}
                  readonly={disabled}
                />
              </Col>

              <Col md={8} sm={24} xs={8}>
                <TextInput
                  formProps={{
                    name: "nombreCorto",
                    label: "Nombre corto",
                    labelCol: { xs: 8 },
                  }}
                  max={100}
                  readonly={disabled}
                />
              </Col>

              <Col md={8} sm={24} xs={8}>
                <NumberInput
                  formProps={{
                    name: "tiemporespuesta",
                    label: "Tiempo de respuesta",
                  }}
                  min={0}
                  max={999999}
                  readonly={disabled}
                />
              </Col>

              <Col md={8} sm={24} xs={8}>
                <NumberInput
                  formProps={{
                    name: "diasRefrigeracion",
                    label: "Días de refrigeración",
                  }}
                  min={0}
                  max={999999}
                  readonly={disabled}
                />
              </Col>
              <Col md={4} sm={24} xs={8}>
                <NumberInput
                  formProps={{
                    name: "dias",
                    label: "Días",
                  }}
                  min={0}
                  max={9999999999999999}
                  readonly={!visible || disabled}
                />
              </Col>

              <Col md={4} sm={24} xs={6}>
                <SwitchInput
                  name="prioridad"
                  label="Prioridad"
                  readonly={disabled}
                />
              </Col>

              <Col md={8} sm={24} xs={8}>
                <NumberInput
                  formProps={{
                    name: "diasEstabilidad",
                    label: "Estabilidad en refrigeración",
                  }}
                  suffix="días"
                  min={0}
                  max={999999}
                  readonly={disabled}
                />
              </Col>
              <Col md={5} xs={6}>
                <SwitchInput
                  name="urgencia"
                  label="Urgencia"
                  readonly={disabled}
                />
              </Col>
              <Col md={5} sm={24} xs={6}>
                <SwitchInput
                  name="visible"
                  label="Visible"
                  onChange={(values) => {
                    setVisible(values);
                  }}
                  readonly={disabled}
                />
              </Col>

              <Col md={5} sm={24} xs={6}>
                <SwitchInput
                  name="activo"
                  label="Activo"
                  onChange={(value) => {
                    if (value) {
                      alerts.info(messages.confirmations.enable);
                    } else {
                      alerts.info(messages.confirmations.disable);
                    }
                  }}
                  readonly={disabled}
                />
              </Col>
              <Col span={16}>
                <SelectInput
                  mode="tags"
                  options={[]}
                  readonly={disabled}
                  formProps={{
                    name: "clavesExternas",
                    label: "Claves externas",
                    labelCol: {
                      xs: { span: 24 },
                      sm: { span: 4 },
                    },
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 20 },
                    },
                    rules: [
                      {
                        validator: async (_, codes: string[]) => {
                          if (
                            codes &&
                            codes.some((x) => x.trim().length === 0)
                          ) {
                            return Promise.reject(new Error("Campo requerido"));
                          }
                          if (codes && codes.some((x) => x.length > 25)) {
                            return Promise.reject(
                              new Error("La longitud máxima es de 25")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ],
                  }}
                />
              </Col>
              <Col span={16}>
                <TextAreaInput
                  formProps={{
                    name: "instrucciones",
                    label: "Instrucciones",
                    labelCol: {
                      xs: { span: 24 },
                      sm: { span: 4 },
                    },
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 20 },
                    },
                  }}
                  rows={6}
                  readonly={disabled}
                />
              </Col>
            </Row>
          </Form>
          <div></div>
          <PageHeader
            ghost={false}
            title={<HeaderTitle title="Listas de trabajo" />}
            className="header-container"
          ></PageHeader>
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={editInputRef}
                  key={tag}
                  size="small"
                  className="tag-input"
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              );
            }

            const isLongTag: boolean = tag.length > 20;

            const tagElem = (
              <Tag
                className="edit-tag"
                key={tag}
                closable={!disabled}
                onClose={() => handleClose(tag)}
              >
                <span
                  onDoubleClick={(e) => {
                    if (index !== 0) {
                      setEditInputIndex(index);
                      setEditInputValue(tag);
                      e.preventDefault();
                    }
                  }}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </span>
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {inputVisible && (
            <Input
              ref={inputRef}
              type="text"
              size="small"
              className="tag-input"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )}
          {!inputVisible && !disabled && (
            <Tag className="site-tag-plus" onClick={showInput}>
              <PlusOutlined /> Nueva
            </Tag>
          )}
          <Divider className="header-divider" />
          <PageHeader
            ghost={false}
            title={<HeaderTitle title="Parámetros del estudio" />}
            className="header-container"
            extra={[
              selectedRowKeysp.length > 0 && !disabled && (
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    deleteParameter();
                  }}
                >
                  Eliminar
                </Button>
              ),
              !disabled && (
                <Button
                  type="primary"
                  onClick={async () => {
                    await ParameterModal(parameterSelected);
                  }}
                >
                  Buscar
                </Button>
              ),
            ]}
          ></PageHeader>
          <Divider className="header-divider" />
        </div>
        <Table<IParameterList>
          size="small"
          rowKey={(record) => record.index ?? -1}
          columns={columnsParameter}
          pagination={false}
          dataSource={[...parameterSelectedSource]}
          scroll={{
            x: windowWidth < resizeWidth ? "max-content" : "auto",
          }}
          rowSelection={rowSelectionp}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
        <br />

        <PageHeader
          ghost={false}
          title={<HeaderTitle title="Indicaciones del estudio" />}
          className="header-container"
          extra={[
            selectedRowKeysi.length > 0 ? (
              <Button type="primary" danger onClick={deleteIndicacion}>
                Eliminar
              </Button>
            ) : (
              ""
            ),
            !disabled && (
              <Button
                type="primary"
                onClick={async () => {
                  await IndicationModal(indicationSelected);
                }}
              >
                Buscar
              </Button>
            ),
          ]}
        ></PageHeader>
        <Divider className="header-divider" />
      </div>
      <Table<IIndicationList>
        size="small"
        rowKey={(record) => record.id}
        columns={columnsIndication}
        pagination={false}
        dataSource={[...indicationSelected]}
        scroll={{
          x: windowWidth < resizeWidth ? "max-content" : "auto",
        }}
        rowSelection={rowSelectioni}
      />
      <Row>
        <Col md={24} sm={12} style={{ marginRight: 20, textAlign: "center" }}>
          <PageHeader
            ghost={false}
            title={
              <HeaderTitle title="Paquete donde se encuentra el estudio" />
            }
            className="header-container"
          ></PageHeader>
          <Divider className="header-divider" />
          <Table<IPacketList>
            size="small"
            columns={columns.slice(0, 3)}
            pagination={false}
            dataSource={[...(values.paquete ?? [])]}
            scroll={{
              x: windowWidth < resizeWidth ? "max-content" : "auto",
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={24} sm={12} style={{ marginRight: 20, textAlign: "center" }}>
          <PageHeader
            ghost={false}
            title={<HeaderTitle title="Etiquetas Asignadas del estudio" />}
            className="header-container"
            extra={[
              selectedRowKeyTags.length > 0 ? (
                <Button type="primary" danger onClick={deleteTag}>
                  Eliminar
                </Button>
              ) : (
                ""
              ),
              !disabled && (
                <Button type="primary" onClick={onSearchTags}>
                  Buscar
                </Button>
              ),
            ]}
          ></PageHeader>
          <Divider className="header-divider" />
          <Table<IStudyTag>
            size="small"
            rowKey={(record) => record.id ?? uuid()}
            columns={columnsTags}
            pagination={false}
            dataSource={[
              ...tagsSelected.map((x) => ({
                ...x,
                cantidad: x.cantidad < 1 ? 1 : Math.round(x.cantidad),
              })),
            ]}
            scroll={{
              x: windowWidth < resizeWidth ? "max-content" : "auto",
            }}
            rowSelection={rowSelectiont}
          />
        </Col>
      </Row>
    </Spin>
  );
};
export default observer(StudyForm);
