import {
  Spin,
  Form,
  Row,
  Col,
  Button,
  PageHeader,
  Divider,
  Pagination,
  List,
} from "antd";
import React, { FC, useEffect, useState } from "react";
import { formItemLayout } from "../../../app/util/utils";
import TextInput from "../../../app/common/form/proposal/TextInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import ImageButton from "../../../app/common/button/ImageButton";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import { observer } from "mobx-react-lite";
import views from "../../../app/util/view";
import {
  IPriceList,
  IPriceListForm,
  PriceListFormValues,
} from "../../../app/models/priceList";
import SwitchInput from "../../../app/common/form/proposal/SwitchInput";
import alerts from "../../../app/util/alerts";
import messages from "../../../app/util/messages";
import { useStore } from "../../../app/stores/store";
import { IFormError } from "../../../app/models/shared";
import { toJS } from "mobx";
import PriceListFormBranches from "./PriceListFormBranches";
import PriceListFormCompanies from "./PriceListFormCompanies";
import PriceListFormPrices from "./PriceListFormPrices";
import PriceListModifyCosts from "./PriceListModifyCosts";

type Props = {
  id: string;
  componentRef: React.MutableRefObject<any>;
  printing: boolean;
  download: boolean;
};

const emptyGuid = "00000000-0000-0000-0000-000000000000";

const PriceListForm: FC<Props> = ({ id, componentRef, printing, download }) => {
  const { priceListStore, modalStore } = useStore();
  const { openModal } = modalStore;
  const {
    studies,
    packs,
    priceLists,
    setStudies,
    setPacks,
    getById,
    getStudiesAndPacks,
    getAll,
    create,
    update,
    scopes,
  } = priceListStore;

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [form] = Form.useForm<IPriceListForm>();

  const [loading, setLoading] = useState(false);
  const [loadingStudies, setLoadingStudies] = useState(false);
  const [readonly, setReadonly] = useState(
    searchParams.get("mode") === "readonly"
  );
  const [errors, setErrors] = useState<IFormError[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [branches, setBranches] = useState<string[]>([]);
  const [currentPrice, setCurrentPrice] = useState<IPriceListForm>(
    new PriceListFormValues()
  );

  useEffect(() => {
    const readPriceList = async (id: string) => {
      setLoading(true);
      const priceList = await getById(id);
      form.setFieldsValue(priceList!);
      setCurrentPrice(priceList!);
      setBranches(priceList!.sucursales);
      setCompanies(priceList!.compañias);
      setLoading(false);
    };

    const readStudies = async (id: string) => {
      setLoadingStudies(true);
      const priceList = await getStudiesAndPacks(id);
      console.log("estudios", priceList!.estudios.length);
      console.log("paquetes", priceList!.paquetes.length);
      setStudies(priceList!.estudios);
      setPacks(priceList!.paquetes);
      setLoadingStudies(false);
    };

    if (id && id !== emptyGuid) {
      readPriceList(id);
    }
    readStudies(id);

    return () => {
      setStudies([]);
      setPacks([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (packs.length > 0 && studies.length > 0) {
      const _packs = toJS(packs);
      // prettier-ignore
      _packs.forEach((p) => {
        p.estudios = studies.filter((s) => p.estudiosId.includes(s.estudioId));
        p.precio = p.precio > 0 ? p.precio : p.estudios.reduce((acc, s) => acc + s.precio, 0);
        p.precioFinal = p.precioFinal > 0 ? p.precioFinal : p.precio;
      });
      setPacks(_packs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packs.length, studies.length]);

  useEffect(() => {
    if (priceLists.length === 0) {
      getAll(searchParams.get("search") ?? "all");
    }
  }, [getAll, priceLists.length, searchParams]);

  const onFinish = async (newValues: IPriceListForm) => {
    // const missingPack = packs.find(
    //   (x) => x.descuentoCantidad > 0 && x.estudios?.some((x) => x.precio <= 0)
    // );
    // if (missingPack) {
    //   alerts.warning(
    //     `Todos los estudios del paquete ${missingPack.clave} deben tener un precio asignado`
    //   );
    //   return;
    // }

    newValues.sucursales = branches;
    newValues.compañias = companies;
    // newValues.estudios = studies.filter((x) => x.precio > 0);
    // newValues.paquetes = packs.filter((x) => x.precio > 0);
    newValues.estudios = studies;
    newValues.paquetes = packs;

    setLoading(true);
    const priceList = { ...currentPrice, ...newValues };
    let response: boolean | IPriceList[];

    if (!priceList.id || priceList.id === emptyGuid) {
      response = await create(priceList);
    } else {
      response = await update(priceList);
    }

    setLoading(false);

    if (typeof response === "object") {
      openModal({
        width: 700,
        title:
          "Las siguientes compañías/sucursales ya existen para otra lista de precios",
        body: (
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={response}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={`Lista de precio: ${item.nombre}`}
                  description={`Compañías: ${item.compañias} | Sucursales: ${item.sucursales}`}
                />
              </List.Item>
            )}
          />
        ),
      });
    }

    if (response === true) {
      goBack();
    }
  };

  const goBack = () => {
    searchParams.delete("mode");
    setSearchParams(searchParams);
    navigate(`/${views.price}?${searchParams}`);
  };

  const setEditMode = () => {
    searchParams.delete("mode");
    setSearchParams(searchParams);
    navigate(`/${views.price}/${id}?${searchParams}&mode=edit`);
    setReadonly(false);
  };

  const getPage = (id: string) => {
    return priceLists.findIndex((x) => x.id === id) + 1;
  };

  const setPage = (page: number) => {
    const priceList = priceLists[page - 1];
    navigate(`/${views.price}/${priceList.id}?${searchParams}`);
  };

  const openModifyCostModal = () => {
    openModal({
      width: 1000,
      title: "Modificar costos",
      body: <PriceListModifyCosts priceList={currentPrice} />,
      onClose: () => {
        navigate(`/${views.price}/${id}?$&mode=readonly`);
        setReadonly(true);
      },
    });
  };

  return (
    <Spin
      spinning={loading || printing || download || loadingStudies}
      tip={printing ? "Imprimiendo" : download ? "Descargando" : ""}
    >
      <Row style={{ marginBottom: 24 }}>
        {!!id && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "left" }}>
            <Pagination
              simple
              key="pagination"
              size="small"
              total={priceLists?.length ?? 0}
              pageSize={1}
              current={getPage(id)}
              onChange={setPage}
              showSizeChanger={false}
            />
          </Col>
        )}
        {!readonly && scopes.modificar && scopes.crear && (
          <Col md={id ? 12 : 24} sm={24} xs={12} style={{ textAlign: "right" }}>
            {!!id &&
              id !== emptyGuid &&
              searchParams.get("mode") === "edit" && (
                <Button
                  onClick={openModifyCostModal}
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    borderColor: "green",
                  }}
                >
                  Modificar costos
                </Button>
              )}
            <Button onClick={goBack}>Cancelar</Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.submit();
              }}
            >
              Guardar
            </Button>
          </Col>
        )}
        {readonly && scopes.modificar && (
          <Col md={12} sm={24} xs={12} style={{ textAlign: "right" }}>
            <ImageButton
              key="edit"
              title="Editar"
              image="editar"
              onClick={setEditMode}
            />
          </Col>
        )}
      </Row>
      <div style={{ display: printing ? "" : "none", height: 300 }}></div>
      <div style={{ display: printing ? "none" : "" }}>
        <div ref={componentRef}>
          {printing && (
            <PageHeader
              ghost={false}
              title={
                <HeaderTitle
                  title="Catálogo de Lista de Precios"
                  image="precio"
                />
              }
              className="header-container"
            ></PageHeader>
          )}
          {printing && <Divider className="header-divider" />}
          <Form<IPriceListForm>
            {...formItemLayout}
            form={form}
            name="priceList"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={new PriceListFormValues()}
            onFinishFailed={({ errorFields }) => {
              const errors = errorFields.map((x) => ({
                name: x.name[0].toString(),
                errors: x.errors,
              }));
              setErrors(errors);
            }}
          >
            <Row gutter={[0, 12]}>
              <Col md={8} sm={24} xs={24}>
                <TextInput
                  formProps={{
                    name: "clave",
                    label: "Clave",
                  }}
                  max={100}
                  required
                  readonly={readonly}
                  errors={errors.find((x) => x.name === "clave")?.errors}
                />
              </Col>
              <Col md={8} sm={24} xs={24}>
                <SwitchInput
                  name="activo"
                  onChange={(value) => {
                    if (value) {
                      alerts.info(messages.confirmations.enable);
                    } else {
                      alerts.info(messages.confirmations.disable);
                    }
                  }}
                  label="Activo"
                  readonly={readonly}
                />
              </Col>
              <Col md={8} sm={0} xs={0}></Col>
              <Col md={8} sm={24} xs={24}>
                <TextInput
                  formProps={{
                    name: "nombre",
                    label: "Nombre",
                  }}
                  max={100}
                  required
                  readonly={readonly}
                  errors={errors.find((x) => x.name === "nombre")?.errors}
                />
              </Col>
              <Col md={8} sm={24} xs={24}>
                <SwitchInput
                  name="visible"
                  onChange={(value) => {
                    if (value) {
                      alerts.info(messages.confirmations.visible);
                    } else {
                      alerts.info(messages.confirmations.visibleweb);
                    }
                  }}
                  label="Visible"
                  readonly={readonly}
                />
              </Col>
            </Row>
          </Form>
          <Row gutter={12}>
            <Col span={12}>
              <PriceListFormCompanies
                readonly={readonly}
                selectedCompanies={companies}
                setSelectedCompanies={setCompanies}
              />
            </Col>
            <Col span={12}>
              <PriceListFormBranches
                readonly={readonly}
                selectedBranches={branches}
                setSelectedBranches={setBranches}
              />
            </Col>
          </Row>
          <PriceListFormPrices
            readonly={readonly}
            studies={studies}
            setStudies={setStudies}
            packs={packs}
            setPacks={setPacks}
          />
        </div>
      </div>
    </Spin>
  );
};

export default observer(PriceListForm);
