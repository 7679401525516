import { ITaxData } from "../models/taxdata";
import { IOptions, IScopes, Scopes } from "../models/shared";
import { makeAutoObservable, toJS } from "mobx";
import alerts from "../util/alerts";
import { getErrors } from "../util/utils";
import InvoiceCompany from "../api/invoiceCompanyLegacy";
import Company from "../api/company";
import Invoice from "../api/invoiceLegacy";
import {
  IInvoicesFreeFilter,
  InvoicesFreeFilterValues,
} from "../models/invoiceLegacy";
import { CompanyFormValues, ICompanyForm } from "../models/company";

export class InvoiceCompanyStoreLegacy {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  selectedRows: any[] = [];
  isSameCommpany: boolean = false;
  setSelectedRows = (rows: any[]) => {
    this.selectedRows = rows;

    this.isSameCommpany = !!rows.length
      ? this.selectedRows.every(
          (request) => request.compania === rows[0].compania
        )
      : false;
  };

  companySelected: ICompanyForm | undefined = new CompanyFormValues();
  setCompanySelected(companySelected: ICompanyForm) {
    this.companySelected = companySelected;
  }
  setPropCompanySelected(propiedades: any) {
    this.companySelected = { ...this.companySelected, ...propiedades };
  }

  selectedRequests: any[] = [];
  setSelectedRequests = (user: string) => {
    this.selectedRequests = this.selectedRows.filter(
      (x) => x.expedienteId === user
    );
  };
  detailInvoice: any = [];
  setDetailInvoice = (detailInvoice: any) => {
    this.detailInvoice = detailInvoice;
  };
  configurationInvoice: any = "";
  setConfigurationInvoice = (config: any) => {
    this.configurationInvoice = config;
  };

  invoices: any = {};
  isLoading: boolean = false;

  getInvoicesCompany = async (filter: any) => {
    try {
      this.isLoading = true;
      const result = await InvoiceCompany.getInvoicesCompany(filter);
      this.invoices = result;
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      alerts.warning(getErrors(error));
    }
  };

  exportForm = async (filter?: IInvoicesFreeFilter) => {
    try {
      if (!!filter) {
        await InvoiceCompany.exportForm(filter);
      } else {
        await InvoiceCompany.exportForm(this.invoicePaymentAddonFilter);
      }
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  invoicesFree: any[] = [];
  getInvoicesFree = async (filter: IInvoicesFreeFilter) => {
    try {
      this.isLoading = true;
      const result = await InvoiceCompany.getInvoicesFree(filter);
      this.invoicesFree = result;
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      alerts.warning(getErrors(error));
    }
  };
  invoicePaymentAddonFilter: IInvoicesFreeFilter =
    new InvoicesFreeFilterValues();
  setInvoicePaymentAddonFilter = (filter: IInvoicesFreeFilter) => {
    this.invoicePaymentAddonFilter = filter;
  };
  invoicesCreditNote: any[] = [];
  getInvoicesCreditNote = async (filter: IInvoicesFreeFilter) => {
    try {
      this.isLoading = true;
      const result = await InvoiceCompany.getInvoicesCreditNote(filter);
      this.invoicesCreditNote = result;
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      alerts.warning(getErrors(error));
    }
  };
  createPaymentAddon = async (result: FormData) => {
    // updateResultPathological = async (result: IResultPathological) => {
    try {
      this.isLoading = true;
      await InvoiceCompany.createPaymentAddon(result);
      alerts.success("Se ha creado el pago correctamente");
      this.getInvoicesCreditNote(this.invoicePaymentAddonFilter);
      this.isLoading = false;
      return true;
    } catch (error: any) {
      this.isLoading = false;
      alerts.warning(getErrors(error));
      return false;
    }
  };

  cancelPaymentAddon = async (id: string) => {
    try {
      this.isLoading = true;
      await InvoiceCompany.cancelPaymentAddon(id);
      alerts.success("Se ha cancelado el pago correctamente");
      this.getInvoicesCreditNote(this.invoicePaymentAddonFilter);
      this.isLoading = false;
      return true;
    } catch (error: any) {
      this.isLoading = false;
      alerts.warning(getErrors(error));
      return false;
    }
  };

  formValues: any = {};
  setFormValues = (filter: any) => {
    this.formValues = filter;
  };

  getCompanyById = async (id: string) => {
    try {
      const company = await Company.getById(id);
      return company;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  serie: string = "";
  setSerie = (serie: string) => {
    this.serie = serie;
    try {
      if (!!serie) {
        this.getConsecutive(serie);
      }
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  consecutiveBySerie: string = "";
  getConsecutive = async (serie: string) => {
    try {
      const consecutiveSerie = await Invoice.GetNextInvoiceSerieNumber(
        serie ?? this.serie
      );
      this.consecutiveBySerie = consecutiveSerie;
      return consecutiveSerie;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  editInfo: boolean = false;
  changeEditInfo = () => {
    this.editInfo = !this.editInfo;
  };
  invoice: any = null;
  setInvoice = (invoice: any) => {
    this.invoice = invoice;
  };
  getInvoice = async (id: string) => {
    try {
      const invoiceData = await InvoiceCompany.getInvoice(id);
      this.invoice = invoiceData;
      return invoiceData;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  checkIn = async (invoiceData: any) => {
    try {
      this.isLoading = true;
      const invoiceInfo = await InvoiceCompany.checkIn(invoiceData);
      this.isLoading = false;
      alerts.success("Factura generada correctamente");
      return invoiceInfo;
    } catch (error: any) {
      this.isLoading = false;
      alerts.warning(getErrors(error));
    }
  };
  taxData: any = {};
  setTaxData = (data: ITaxData) => {
    this.taxData = data;
  };
  nombreSeleccionado: string = "";
  setNombreSeleccionado = (nombre: string) => {
    this.nombreSeleccionado = nombre;
  };
  downloadPdf = async (facturapiId: string) => {
    try {
      const invoiceInfo = await InvoiceCompany.downloadPdf(facturapiId);
      return invoiceInfo;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
  downloadXML = async (facturapiId: string) => {
    try {
      const invoiceInfo = await InvoiceCompany.downloadXML(facturapiId);
      return invoiceInfo;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  printPdf = async (facturapiId: string) => {
    try {
      const invoiceInfo = await InvoiceCompany.printPdf(facturapiId);
      return invoiceInfo;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
  printPaymentAddon = async (paymentId: string) => {
    try {
      const invoiceInfo = await InvoiceCompany.printPaymentAddon(paymentId);
      return invoiceInfo;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  fechas: any[] = [];
  saveFilterDate = (fechas: any[]) => {
    this.fechas = fechas;
  };
  isLoadingDeliver: boolean = false;
  sendInvoice = async (sendInvoiceData: any) => {
    try {
      this.isLoadingDeliver = true;
      const responseDeliver = await InvoiceCompany.sendInvoice(sendInvoiceData);
      this.isLoadingDeliver = false;
      return responseDeliver;
      // return invoiceInfo;
    } catch (error: any) {
      this.isLoadingDeliver = false;
      alerts.warning(getErrors(error));
    }
  };
  cancelInvoice = async (cancelInvoiceData: any) => {
    try {
      this.isLoading = true;
      await InvoiceCompany.cancelInvoice(cancelInvoiceData);
      alerts.info("Factura cancelada correctamente");
      // this.getInvoicesCompany(this.formValues);
      this.isLoading = false;

      return;
      // return invoiceInfo;
    } catch (error: any) {
      this.isLoading = false;
      alerts.warning(getErrors(error));
    }
  };

  printReceipt = async (receiptCompanyData: any) => {
    try {
      const reciptInfo = await InvoiceCompany.printReceipt(receiptCompanyData);
      return reciptInfo;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
  selectedRequestGlobal: any[] = [];
  setSelectedRequestGlobal = async (selectedRequestGlobal: any[]) => {
    this.selectedRequestGlobal = selectedRequestGlobal;
  };

  isLoadingGlobal = false;
  createInvoiceGlobal = async (sucursalId: string) => {
    if (!this.selectedRequestGlobal.length) {
      alerts.info("Sin solicitudes seleccionadas");
      return;
    }
    try {
      alerts.info("Se estan generando las solicitudes");
      this.isLoadingGlobal = true;
      const response = await InvoiceCompany.checkInGlobal({
        sucursalId: sucursalId,
        solicitudesId: this.selectedRequestGlobal,
      });
      this.isLoadingGlobal = false;
      if (!!response.solicitudesFallidas.length) {
        alerts.warning(
          `Facturas no generadas por datos faltantes: ${response.solicitudesFallidas.join(
            ", "
          )}`
        );
      } else {
        alerts.info("Facturas generadas correctamente");
      }

      alerts.info("Se estan generando las solicitudes");
      // this.getInvoicesCompany(this.formValues);
      return response;
    } catch (error: any) {
      this.isLoadingGlobal = false;
      alerts.warning(getErrors(error));
    }
  };
}
