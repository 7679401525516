import {
  IPriceListForm,
  IPriceListInfoFilter,
  IPriceListInfoPack,
  IPriceListInfoStudy,
  IPriceList,
} from "../models/priceList";
import { IOptions, IScopes } from "../models/shared";
import requests from "./agent";

const PriceList = {
  access: (): Promise<IScopes> => requests.get("scopes/price"),
  getAll: (search: string): Promise<IPriceList[]> =>
    requests.get(`price/all/${!search ? "all" : search}`),
  getOptions: (type: "p" | "c" | "all"): Promise<IOptions[]> =>
    requests.get(`price/options/${type}`),
  getBranchesOptionsByPriceListId: (id: string): Promise<IOptions[]> =>
    requests.get(`price/branches/options/${id}`),
  getById: (id: string): Promise<IPriceListForm> => requests.get(`price/${id}`),
  getStudiesAndPacks: (id: string): Promise<IPriceListForm> =>
    requests.get(`price/studies/${id}`),
  getPriceStudy: (
    filter?: IPriceListInfoFilter
  ): Promise<IPriceListInfoStudy> =>
    requests.post(`price/info/study`, filter ?? {}),
  getPriceStudies: (
    filter?: IPriceListInfoFilter
  ): Promise<IPriceListInfoStudy[]> =>
    requests.post(`price/info/studies`, filter ?? {}),
  getPricePack: (filter?: IPriceListInfoFilter): Promise<IPriceListInfoPack> =>
    requests.post(`price/info/pack`, filter ?? {}),
  getPricePackages: (
    filter?: IPriceListInfoFilter
  ): Promise<IPriceListInfoPack[]> =>
    requests.post(`price/info/packages`, filter ?? {}),
  create: (priceList: IPriceListForm): Promise<IPriceList> =>
    requests.post("price", priceList),
  createHistory: (priceList: any): Promise<any> =>
    requests.post("price/history/create", priceList),
  getHistory: (id: string): Promise<any> => requests.get(`price/history/${id}`),
  update: (priceList: IPriceListForm): Promise<IPriceList> =>
    requests.put("price", priceList),
  exportList: (search: string): Promise<void> =>
    requests.download(`price/export/list/${!search ? "all" : search}`),
  exportForm: (id: string): Promise<void> =>
    requests.download(`price/export/form/${id}`),
};

export default PriceList;
