import { makeAutoObservable } from "mobx";

import PriceList from "../api/priceList";
import {
  IPriceList,
  IPriceListForm,
  IPriceListInfoFilter,
  IPriceListPack,
  IPriceListStudy,
} from "../models/priceList";
import { IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import history from "../util/history";
import messages from "../util/messages";
import responses from "../util/responses";
import { getErrors } from "../util/utils";

export default class PriceListStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  priceLists: IPriceList[] = [];
  studies: IPriceListStudy[] = [];
  packs: IPriceListPack[] = [];

  clearScopes = () => {
    this.scopes = new Scopes();
  };

  clearPriceList = () => {
    this.priceLists = [];
  };

  setStudies = (studies: IPriceListStudy[]) => {
    this.studies = studies;
  };

  setPacks = (packs: IPriceListPack[]) => {
    this.packs = packs;
  };

  // prettier-ignore
  updatePackPrices = (study: IPriceListStudy) => {
    if (this.packs.length === 0) return;

    const packs = [...this.packs];
    for (const pack of packs.filter(x => x.estudiosId.includes(study.estudioId))) {
      if(!pack.estudios) continue;
      pack.precio = pack.estudios.reduce((acc, o) => acc + o.precio, 0);
      pack.descuentoCantidad = Number(
        ((pack.precio * pack.descuentoPorcentaje) / 100).toFixed(2)
      );
      pack.precioFinal = pack.precio - pack.descuentoCantidad;
    }

    this.packs = packs;
  };

  updatePackPricesAll = () => {
    if (this.packs.length === 0) return;

    const packs = [...this.packs];
    for (const pack of packs) {
      if (!pack.estudios) continue;
      const nuevosEstudios = this.studies.filter((x) =>
        pack.estudiosId.includes(x.estudioId)
      );
      pack.estudios = nuevosEstudios;
      pack.precio = pack.estudios.reduce((acc, o) => acc + o.precio, 0);
      pack.descuentoCantidad = Number(
        ((pack.precio * pack.descuentoPorcentaje) / 100).toFixed(2)
      );
      pack.precioFinal = pack.precio - pack.descuentoCantidad;
    }

    this.packs = packs;
  };

  access = async () => {
    try {
      const scopes = await PriceList.access();
      this.scopes = scopes;
      return scopes;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      history.push("/forbidden");
    }
  };

  getAll = async (search: string) => {
    try {
      const priceLists = await PriceList.getAll(search);
      this.priceLists = priceLists;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      this.priceLists = [];
    }
  };

  getById = async (id: string) => {
    try {
      const priceList = await PriceList.getById(id);
      return priceList;
    } catch (error: any) {
      if (error.status === responses.notFound) {
        history.push("/notFound");
      } else {
        alerts.warning(getErrors(error));
      }
    }
  };

  getStudiesAndPacks = async (id: string) => {
    try {
      const priceList = await PriceList.getStudiesAndPacks(id);
      return priceList;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  getPriceStudy = async (filter?: IPriceListInfoFilter) => {
    try {
      const price = await PriceList.getPriceStudy(filter);
      return price;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  getPricePack = async (filter?: IPriceListInfoFilter) => {
    try {
      const price = await PriceList.getPricePack(filter);
      return price;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  create = async (
    priceList: IPriceListForm
  ): Promise<boolean | IPriceList[]> => {
    try {
      const newPriceList = await PriceList.create(priceList);
      alerts.success(messages.created);
      this.priceLists.push(newPriceList);
      return true;
    } catch (error: any) {
      if (
        error.status === responses.conflict &&
        error.data?.errors?.toString()?.startsWith("[") &&
        error.data?.errors?.toString()?.endsWith("]")
      ) {
        const repeated = JSON.parse(error.data.errors) as IPriceList[];
        return repeated;
      } else {
        alerts.warning(getErrors(error));
        return false;
      }
    }
  };

  createHistory = async (record: any) => {
    try {
      await PriceList.createHistory(record);
      alerts.success(messages.created);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  getHistory = async (id: string) => {
    try {
      const history = await PriceList.getHistory(id);
      return history;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  update = async (
    priceList: IPriceListForm
  ): Promise<boolean | IPriceList[]> => {
    try {
      const updatedPriceList = await PriceList.update(priceList);
      alerts.success(messages.updated);
      const id = this.priceLists.findIndex((x) => x.id === priceList.id);
      if (id !== -1) {
        this.priceLists[id] = updatedPriceList;
      }
      return true;
    } catch (error: any) {
      if (
        error.status === responses.conflict &&
        error.data?.errors?.toString()?.startsWith("[") &&
        error.data?.errors?.toString()?.endsWith("]")
      ) {
        const repeated = JSON.parse(error.data.errors) as IPriceList[];
        return repeated;
      } else {
        alerts.warning(getErrors(error));
        return false;
      }
    }
  };

  exportList = async (search: string) => {
    try {
      await PriceList.exportList(search);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  exportForm = async (id: string) => {
    try {
      await PriceList.exportForm(id);
    } catch (error: any) {
      if (error.status === responses.notFound) {
        history.push("/notFound");
      } else {
        alerts.warning(getErrors(error));
      }
    }
  };
}
