import { makeAutoObservable, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";

import ClinicResults from "../api/clinicResults";
import {
  IClinicResultCaptureForm,
  IClinicResultList,
  IClinicStudy,
  IPrintTypes,
  IResultPrintPathological,
} from "../models/clinicResults";
import { IGeneralForm } from "../models/general";
import { IOptions, IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import history from "../util/history";
import messages from "../util/messages";
import { getErrors } from "../util/utils";
import { IBranchForm } from "../models/branch";

export default class ClinicResultsStores {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  data: IClinicResultList[] = [];
  studies: IClinicStudy[] = [];
  loadingStudies: boolean = false;
  clear: boolean = false;
  studiesSelectedToPrint: IPrintTypes[] = [];
  observationsSelected: IOptions[] = [];
  observationText: string = "";
  lastViewedCode?: string;
  pathologicalPdf: any = null;

  getHistoryResults = (
    requestId: string,
    studyId: number,
    parameterId: string
  ) => {
    return ClinicResults.getHistoryResults(requestId, studyId, parameterId);
  };

  getHistoryResultsByRecord = (
    recordId: string,
    parameterId: string,
    filters: any
  ) => {
    return ClinicResults.getHistoryResultsByRecord(
      recordId,
      parameterId,
      filters
    );
  };

  printResultPathologicalFilePreview = (pdfInfo: any) => {
    return ClinicResults.printResultPathologicalFilePreview(pdfInfo);
  };

  setPathologicalPdf = (pdf: any) => {
    this.pathologicalPdf = pdf;
  };

  printSelectedStudies = async (configuration: any) => {
    try {
      await ClinicResults.printSelectedStudies(configuration);
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  printStudiesQR = async (configuration: any) => {
    try {
      return await ClinicResults.printStudiesQR(configuration);
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  setObservationsSelected = (observationsSelected: IOptions[]) => {
    this.observationsSelected = observationsSelected;
  };

  setObservationsText = (observationText: string) => {
    this.observationText = observationText;
  };

  getObservationsSelected = () => {
    return this.observationsSelected;
  };

  getObservationsText = () => {
    return this.observationText;
  };

  clearScopes = () => {
    this.scopes = new Scopes();
  };

  clearStudy = () => {
    this.data = [];
  };

  addSelectedStudy = (estudio: IPrintTypes) => {
    this.studiesSelectedToPrint = [...this.studiesSelectedToPrint, estudio];
  };

  getDeliveryHistory = async (solicitudId: string) => {
    try {
      const historial = await ClinicResults.getDeliveryHistory(solicitudId);
      return historial;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };
  createNoteDeliveryHistory = async (nota: any) => {
    try {
      const historial = await ClinicResults.createNoteDeliveryHistory(nota);
      return historial;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  clearSelectedStudies = () => {
    this.studiesSelectedToPrint = [];
  };

  clearStudiesParams = () => {
    this.studies = [];
  };

  removeSelectedStudy = (estudio: IPrintTypes) => {
    this.studiesSelectedToPrint = this.studiesSelectedToPrint.filter(
      (item) => item.id !== estudio.id
    );
  };

  access = async () => {
    try {
      const scopes = await ClinicResults.access();
      this.scopes = scopes;
    } catch (error) {
      alerts.warning(getErrors(error));
      history.push("/forbidden");
    }
  };

  updatePathologicalPdf = async (pdf: FormData) => {
    try {
      await ClinicResults.updatePathologicalPdf(pdf);
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  getAll = async (search: IGeneralForm) => {
    try {
      this.loadingStudies = true;
      const study = await ClinicResults.getAll(search);
      this.data = study;
      return study;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.data = [];
    } finally {
      this.loadingStudies = false;
    }
  };

  createResults = async (results: IClinicResultCaptureForm[]) => {
    try {
      await ClinicResults.createResults(results);
      alerts.success(messages.created);
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  updateResults = async (
    results: IResultPrintPathological,
    recordId: string,
    envioManual: boolean
  ) => {
    try {
      const updatedResults = await ClinicResults.updateResults(
        results,
        envioManual
      );
      console.log("updatedResults", toJS(updatedResults));
      alerts.success(messages.updated);
      const index = this.studies.findIndex(
        (x) => x.id === results.cliniciResultCaptureForm[0].solicitudEstudioId
      );
      if (index !== -1) {
        this.studies[index] = {
          ...this.studies[index],
          parametros: this.studies[index].parametros.map((x) => {
            const par = updatedResults.find((p) => p.id === x.id);

            return {
              ...x,
              resultado: par?.resultado,
              hasHistory: par?.hasHistory,
            };
          }),
        };
      }
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  cancelResults = async (id: number) => {
    try {
      this.studies = this.studies.map((x) => {
        if (x.id !== id) return x;
        else {
          return {
            ...x,
            parametros: x.parametros.map((p) => ({
              ...p,
              resultado: undefined,
            })),
          };
        }
      });
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  createResultPathological = async (result: FormData) => {
    // createResultPathological = async (result: IResultPathological) => {
    try {
      await ClinicResults.createResultPathological(result);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };
  getResultPathological = async (result: number) => {
    try {
      const resultPathological = await ClinicResults.getResultPathological(
        result
      );
      return resultPathological;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  updateResultPathological = async (result: FormData, envioManual: boolean) => {
    // updateResultPathological = async (result: IResultPathological) => {
    try {
      await ClinicResults.updateResultPathological(result, envioManual);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };
  sendResultFile = async (listResult: any) => {
    // updateResultPathological = async (result: IResultPathological) => {
    try {
      const success = await ClinicResults.sendResultFile(listResult);
      return success;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  printResultFile = async (listResult: any) => {
    try {
      const success = await ClinicResults.printResultFile(listResult);
      return success;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  printResultFilePreview = async (listResult: any) => {
    try {
      const url = await ClinicResults.printResultFilePreview(listResult);
      return url;
    } catch (error) {
      alerts.warning(getErrors(error));
    }
  };

  updateStatusStudy = async (
    requestStudyId: number,
    status: number,
    sucursalActiva?: string
  ) => {
    // updateResultPathological = async (result: IResultPathological) => {
    try {
      await ClinicResults.updateStatusStudy(
        requestStudyId,
        status,
        sucursalActiva
      );
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  getRequestStudyById = async (requestStudyId: number) => {
    try {
      const requestStudy = await ClinicResults.getRequestStudyById(
        requestStudyId
      );
      return requestStudy;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  getLabResultsByStudyId = async (id: number) => {
    try {
      const results = await ClinicResults.getLabResultsByStudyId(id);
      return results;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  exportList = async (search: IGeneralForm) => {
    try {
      await ClinicResults.exportList(search);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  exportGlucose = async (results: IClinicResultCaptureForm) => {
    try {
      this.loadingStudies = true;
      await ClinicResults.exportGlucose(results);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingStudies = false;
    }
  };

  getStudies = async (recordId: string, requestId: string) => {
    try {
      const params = await ClinicResults.getStudies(recordId, requestId);
      this.studies = params.estudios.map((x) => ({
        identificador: uuidv4(),
        id: x.id!,
        estudioId: x.estudioId,
        clave: x.clave,
        nombre: x.nombre,
        status: x.estatusId,
        solicitudEstudioId: x.id,
        sucursalCaptura: x.sucursalCaptura,
        capturado: x.capturado,
        parametros: x.parametros.map((y, i) => ({
          id: y.resultadoId,
          estudioId: x.estudioId,
          solicitudId: requestId,
          estatus: x.estatusId,
          resultado: y.resultado,
          formula: y.formula!,
          parametroId: y.id,
          nombre: y.nombre,
          valorInicial: y.valorInicial,
          valorFinal: y.valorFinal,
          unidades: y.unidades,
          unidadNombre: y.unidadNombre,
          tipoValorId: y.tipoValor,
          solicitudEstudioId: x.id!,
          tipoValores: y.tipoValores!,
          criticoMinimo: y.criticoMinimo,
          criticoMaximo: y.criticoMaximo,
          ultimoResultado: y.ultimoResultado,
          ultimaSolicitud: y.ultimaSolicitud,
          ultimaSolicitudId: y.ultimaSolicitudId,
          ultimoExpedienteId: y.ultimoExpedienteId,
          hasHistory: y.hasHistory,
          deltaCheck: y.deltaCheck,
          editable: y.editable,
          observacionesId: y.observacionesId,
          orden: y.index!,
          clave: y.clave,
          sucursalCaptura: x.sucursalCaptura,
          capturado: x.capturado,
          requerido: y.requerido,
          rango:
            !y.criticoMinimo || !y.criticoMaximo
              ? false
              : y.criticoMinimo >= parseFloat(y.resultado) ||
                parseFloat(y.resultado) >= y.criticoMaximo,
        })),
      }));
      return params;
    } catch (error) {
      alerts.warning(getErrors(error));
      return [];
    }
  };

  changeParameterRange = (id: string, estudioId: number) => {
    let studyIndex = this.studies.findIndex((x) => x.estudioId === 1612);
    let study = this.studies[studyIndex];
    let parameterIndex = study.parametros.findIndex(
      (x) => x.id === "44d55b73-5396-4240-b2b5-2c0ba35dfe58"
    );
    this.studies[studyIndex].parametros[parameterIndex].rango =
      !this.studies[studyIndex].parametros[parameterIndex].rango;
  };

  printResults = async (recordId: string, requestId: string) => {
    try {
      await ClinicResults.printResults(recordId, requestId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
