import { observer } from "mobx-react-lite";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import CheckInput from "../../../app/common/form/CheckInput";
import { toJS } from "mobx";
import SelectInput from "../../../app/common/form/proposal/SelectInput";
import NumberInput from "../../../app/common/form/proposal/NumberInput";
import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../app/common/table/utils";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import {
  IPriceListForm,
  IPriceListPack,
  IPriceListStudy,
} from "../../../app/models/priceList";
import HeaderTitle from "../../../app/common/header/HeaderTitle";
import alerts from "../../../app/util/alerts";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

type Props = {
  priceList: IPriceListForm;
};

const PriceListModifyCosts = ({ priceList }: Props) => {
  const { optionStore, priceListStore, profileStore, modalStore } = useStore();
  const {
    priceListOptions,
    getPriceListOptions,
    getUsersOptions,
    userOptions,
  } = optionStore;
  const { closeModal } = modalStore;
  const { profile } = profileStore;
  const {
    updatePackPricesAll,
    studies,
    setStudies,
    setPacks,
    getStudiesAndPacks,
    update: updatePriceList,
    packs,
    createHistory,
    getHistory,
  } = priceListStore;
  const navigate = useNavigate();
  const [duplicate, setDuplicate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [redondeo, setRedondeo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<any[]>([]);
  const [disableSave, setDisableSave] = useState(true);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const [form] = Form.useForm();
  const checkValues = Form.useWatch("check", form);
  const options = [
    { label: "Replicar Lista de Precios", value: "replica" },
    { label: "Actualiza Costos", value: "actualiza" },
  ];
  const columns: IColumns<any> = [
    {
      ...getDefaultColumnProps("accion", "Acción", {
        searchState,
        setSearchState,
        width: "15%",
      }),
      render: (text) => (text !== null ? <Text>{text}</Text> : <> - </>),
    },
    {
      ...getDefaultColumnProps("porcentaje", "Porcentaje aplicado", {
        searchState,
        setSearchState,
        width: "20%",
      }),
      render: (text) => (text !== -1 ? <Text>{text}%</Text> : <> - </>),
    },
    {
      ...getDefaultColumnProps("listaPrecioRep", "Lista replicada", {
        searchState,
        setSearchState,
        width: "20%",
      }),
      render: (text) => {
        return priceListOptions.find((x) => x.key === text)?.label ?? " - ";
      },
    },
    {
      ...getDefaultColumnProps("usuarioId", "Usuario", {
        searchState,
        setSearchState,
        width: "20%",
      }),
      render: (text) =>
        userOptions.find((x) => x.value === text)?.extra?.clave ?? " - ",
    },

    {
      ...getDefaultColumnProps("fecha", "Fecha y Hora", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
  ];
  useEffect(() => {
    if (!update) {
      form.resetFields(["inc", "porcentaje", "redondeo"]);
      if (!duplicate) {
        setDisableSave(true);
      }
      setRedondeo(false);
    }
    if (update) {
      form.setFieldValue("inc", "incrementar");
      form.setFieldValue("redondeo", true);
      setRedondeo(true);
      setDisableSave(false);
    }
  }, [update, form]);

  useEffect(() => {
    if (!duplicate) {
      form.resetFields(["listaPreciosRep"]);
      if (!update) {
        setDisableSave(true);
      }
    }
    if (duplicate) {
      setDisableSave(false);
    }
  }, [duplicate, form]);
  useEffect(() => {
    getPriceListOptions("all");
    getHistory(priceList.id).then((data) => {
      setHistory(data);
    });
    getUsersOptions();
  }, []);
  useEffect(() => {
    if (checkValues?.includes("replica")) {
      setDuplicate(true);
    } else {
      setDuplicate(false);
    }
    if (checkValues?.includes("actualiza")) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
  }, [checkValues]);

  const updatePackPricesAllLocal = (
    paquetes: IPriceListPack[],
    estudios: IPriceListStudy[]
  ): IPriceListPack[] => {
    if (paquetes.length === 0) return [];

    const packs = [...paquetes];
    for (const pack of packs) {
      if (!pack.estudios) continue;
      const nuevosEstudios = estudios.filter((x) =>
        pack.estudiosId.includes(x.estudioId)
      );
      pack.estudios = nuevosEstudios;
      pack.precio = pack.estudios.reduce((acc, o) => acc + o.precio, 0);
      pack.descuentoCantidad = Number(
        ((pack.precio * pack.descuentoPorcentaje) / 100).toFixed(2)
      );
      pack.precioFinal = pack.precio - pack.descuentoCantidad;
    }

    return packs;
  };

  const actualizarCostos = async (values: any) => {
    setLoading(true);
    let studiesUpdate: IPriceListStudy[] = [];
    let estudiosAModificar: IPriceListStudy[] = [...studies];
    let packsAModificar: IPriceListPack[] = [...packs];
    if (checkValues?.includes("replica")) {
      console.log("replica");
      const priceListToUpdate = await getStudiesAndPacks(
        values.listaPreciosRep
      );
      console.log("priceListToUpdate", priceListToUpdate);

      const estudios = priceListToUpdate?.estudios;
      studiesUpdate = estudiosAModificar?.map((x) => {
        const estudio = estudios?.find((y) => y.estudioId === x.estudioId);

        const estudioActualizado = {
          ...x,
          precio: estudio?.precio,
          activo: estudio?.activo,
        } as IPriceListStudy;

        return estudioActualizado;
      });
      let paquetes = priceListToUpdate?.paquetes;
      packsAModificar = packsAModificar.map((x) => {
        const paquete = paquetes?.find((y) => y.paqueteId === x.paqueteId);

        const paqueteActualizado = {
          ...x,
          // precio: paquete?.precio,
          activo: paquete?.activo,
        } as IPriceListPack;

        return paqueteActualizado;
      });
      setPacks(packsAModificar);
      estudiosAModificar = studiesUpdate;
      packsAModificar = updatePackPricesAllLocal(
        packsAModificar,
        studiesUpdate
      );
    }
    if (checkValues?.includes("actualiza")) {
      console.log("actualiza");
      studiesUpdate = estudiosAModificar.map((x) => {
        if (x.activo) {
          const nuevoPrecio =
            values?.inc === "incrementar"
              ? x.precio + (x.precio * values.porcentaje) / 100
              : x.precio - (x.precio * values.porcentaje) / 100;
          const estudio = {
            ...x,
            precio: redondeo ? Math.ceil(nuevoPrecio) : nuevoPrecio,
          };

          return estudio;
        }
        return x;
      });
      console.log("estudiosActualizados", toJS(studiesUpdate));
      estudiosAModificar = studiesUpdate;
      packsAModificar = updatePackPricesAllLocal(
        packsAModificar,
        studiesUpdate
      );
    }
    setStudies(estudiosAModificar);
    updatePackPricesAll();
    await updatePriceList({
      ...priceList,
      estudios: estudiosAModificar,
      paquetes: packsAModificar,
    });

    createHistory({
      accion:
        !!values?.inc === false
          ? null
          : values?.inc === "incrementar"
          ? "Incrementar"
          : "Decrementar",
      porcentaje: values?.porcentaje ?? -1,
      lista:
        priceListOptions.find((x) => x.key === values?.listaPreciosRep)
          ?.label ?? " - ",
      listaPrecioRep: values?.listaPreciosRep,
      listaPrecioId: priceList.id,
      usuario: profile?.nombre,
      fecha: new Date().toLocaleString(),
      date: new Date(),
    });

    form.resetFields();
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    console.log("values", values);
    alerts.confirm(
      "Confirmar actualización de costos",
      "Se actualizarán los Costos, Estudios y Paquetes activos para esta lista de precios. ¿Desea continuar?",
      async () => {
        await actualizarCostos(values);

        closeModal();
      }
    );
  };

  return (
    <>
      <Spin spinning={loading}>
        <Col span={24}>
          <Row>
            <HeaderTitle
              title={`${priceList.nombre} (${priceList.clave})`}
            ></HeaderTitle>
          </Row>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              redondeo: false,
              check: [],
            }}
          >
            <Row>
              <Col span={12}>
                <Form.Item name="check">
                  <Checkbox.Group>
                    {options.map((option) => (
                      <>
                        <Checkbox key={option.value} value={option.value}>
                          {option.label}
                        </Checkbox>
                        <br />
                      </>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={12} style={{ textAlign: "center" }}>
                <SelectInput
                  formProps={{
                    label: "Lista de Precios",
                    name: "listaPreciosRep",
                  }}
                  options={priceListOptions.filter(
                    (x) => x.key !== priceList.id
                  )}
                  required={duplicate}
                  readonly={!duplicate}
                ></SelectInput>
              </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }} justify="center">
              <Col span={6} style={{ textAlign: "left" }}>
                <Form.Item name="inc" required={update}>
                  <Radio.Group disabled={!update}>
                    <Radio value={"incrementar"}>Incrementar</Radio>
                    <br />
                    <Radio value={"decrementar"}>Decrementar</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <NumberInput
                  formProps={{
                    name: "porcentaje",
                    label: "%",
                  }}
                  min={0}
                  // max={100}
                  required={update}
                  readonly={!update}
                  reglas={[
                    {
                      validator: (_, value) =>
                        value === 0
                          ? Promise.reject(
                              new Error("El porcentaje debe ser positivo")
                            )
                          : Promise.resolve(),
                    },
                  ]}
                ></NumberInput>
                <Form.Item name="redondeo">
                  <Checkbox
                    checked={redondeo}
                    disabled={!update}
                    value={redondeo}
                    onChange={(value) => {
                      setRedondeo(value.target.checked);
                    }}
                  >
                    Aplicar redondeo
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row justify="center">
            <Button
              onClick={() => {
                closeModal();
                form.resetFields();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableSave}
              type="primary"
              onClick={() => form.submit()}
            >
              Guardar
            </Button>
          </Row>
          <Row>
            <Col span={24}>
              <Divider></Divider>
              <HeaderTitle title="Historial de Modificaciones"></HeaderTitle>
              <Table
                size="small"
                dataSource={history}
                columns={columns}
              ></Table>
            </Col>
          </Row>
        </Col>
      </Spin>
    </>
  );
};

export default observer(PriceListModifyCosts);
