import { Button, Col, Form, Input, Row } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";

import DateRangeInput from "../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../app/common/form/proposal/SelectInput";
import SwitchInput from "../../app/common/form/proposal/SwitchInput";
import TextInput from "../../app/common/form/proposal/TextInput";
import TimeRangeInput from "../../app/common/form/proposal/TimeRangeInput";
import { IReportFilter } from "../../app/models/report";
import { ISeriesList } from "../../app/models/series";
import { IOptions } from "../../app/models/shared";
import { useStore } from "../../app/stores/store";
import { formItemLayout } from "../../app/util/utils";
import {
  originOptions,
  studyStatusOptions,
} from "../../app/stores/optionStore";

type ReportFilterProps = {
  input: (
    | "sucursal"
    | "fecha"
    | "medico"
    | "metodoEnvio"
    | "compañia"
    | "urgencia"
    | "tipoCompañia"
    | "fechaIndividual"
    | "hora"
    | "tipoFecha"
    | "origen"
    | "destino"
    | "buscar"
    | "estatus"
    | "limpiar"
    | "serie"
    | "procedencia"
  )[];
  setShowChart: React.Dispatch<React.SetStateAction<boolean>>;
};

const sendMethodOptions: IOptions[] = [
  {
    value: 1,
    label: "Correo",
  },
  {
    value: 2,
    label: "WhatsApp",
  },
];

const urgentOptions: IOptions[] = [
  {
    value: 1,
    label: "Urgencia",
  },
  {
    value: 2,
    label: "Urgencia con cargo",
  },
];

const typeCompanyOptions: IOptions[] = [
  {
    value: 1,
    label: "Convenio",
  },
  {
    value: 2,
    label: "Todas",
  },
];

const typeCompanyOptionsInvoice: IOptions[] = [
  {
    value: 1,
    label: "Convenio",
  },
  {
    value: 2,
    label: "Particular",
  },
];

const statusOptions: IOptions[] = [
  {
    value: 1,
    label: "Vigente",
  },
  {
    value: 3,
    label: "Cancelado",
  },
];

const ReportFilter = ({ input, setShowChart }: ReportFilterProps) => {
  const { reportStore, optionStore, profileStore } = useStore();
  const {
    currentReport,
    filter,
    setFilter,
    getByFilter,
    getByChart,
    setDetail,
  } = reportStore;
  const {
    branchCityOptions,
    medicOptions,
    companyOptions,
    getBranchOptions,
    getMaquiladorOptions,
    BranchOptions,
    ReportMaquiladorOptions,
    getMedicOptions,
    getCompanyOptions,
    getAllInvoiceSeriesOptions,
    getAllInvoiceSeriesList,
    invoiceSeriesList,
  } = optionStore;
  const { profile } = profileStore;

  const [form] = Form.useForm<IReportFilter>();

  const chartValue = Form.useWatch("grafica", form) ?? false;
  const selectedCity = Form.useWatch("ciudad", form);
  const selectedBrach = Form.useWatch("sucursalId", form);
  const [cityOptions, setCityOptions] = useState<IOptions[]>([]);
  const [branchOptions, setBranchOptions] = useState<IOptions[]>([]);
  const [seriesOptions, setSeriesOptions] = useState<IOptions[]>([]);
  const [, setOriginBranch] = useState<string>("");

  useEffect(() => {
    getMedicOptions();
    getCompanyOptions();
    getBranchOptions();
    getMaquiladorOptions();
    getAllInvoiceSeriesList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getMedicOptions,
    getCompanyOptions,
    getBranchOptions,
    getMaquiladorOptions,
    getAllInvoiceSeriesOptions,
  ]);

  useEffect(() => {
    setCityOptions(
      branchCityOptions.map((x) => ({ value: x.value, label: x.label }))
    );
  }, [branchCityOptions]);

  useEffect(() => {
    changeSerie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceSeriesList]);

  useEffect(() => {
    if (!profile || !profile.sucursal || branchCityOptions.length === 0) return;
    const profileBranch = profile.sucursal;
    const userCity = branchCityOptions
      .find((x) => x.options!.some((y) => y.value === profileBranch))
      ?.value?.toString();

    const reportFilter = {
      ...filter,
      ciudad: !filter.cargaInicial ? filter.ciudad : [userCity as string],
      sucursalId: !filter.cargaInicial ? filter.sucursalId : [profileBranch],
      origen: !filter.cargaInicial ? filter.origen : [profileBranch],
      tipoFecha: "Activas",
    };

    form.setFieldsValue(reportFilter);
    filter.cargaInicial = false;

    setOriginBranch(profileBranch);
    setFilter(reportFilter);

    const getReport = async () => {
      await getByFilter(currentReport!, reportFilter);
    };

    if (currentReport) {
      getReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchCityOptions]);

  useEffect(() => {
    if (selectedCity != null && selectedCity != null) {
      var branhces = branchCityOptions.filter((x) =>
        selectedCity.includes(x.value.toString())
      );
      var options = branhces.flatMap((x) =>
        x.options == null ? [] : x.options
      );
      setBranchOptions(options);
    }
  }, [branchCityOptions, form, selectedCity]);

  useEffect(() => {
    if (chartValue === filter.grafica) return;
    setShowChart(chartValue);
    setFilter({ ...filter, grafica: chartValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartValue, setShowChart]);

  useEffect(() => {
    changeSerie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, selectedCity, selectedBrach]);

  const changeSerie = async () => {
    if (selectedCity?.length > 0 || selectedBrach?.length > 0) {
      let series: ISeriesList[] = [];
      if (selectedCity.length > 0) {
        series = invoiceSeriesList.filter(
          (o) =>
            selectedCity.includes(o.ciudad) ||
            (selectedBrach.includes(o.sucursalId) && selectedBrach.length > 0)
        );
      }
      if (selectedBrach.length > 0) {
        series = series.filter((o) => selectedBrach.includes(o.sucursalId));
      }

      setSeriesOptions(
        series.map((x) => ({
          key: x.id,
          value: x.clave,
          label: x.clave,
        }))
      );
    } else {
      setSeriesOptions(
        invoiceSeriesList.map((x) => ({
          key: x.id,
          value: x.clave,
          label: x.clave,
        }))
      );
    }
  };

  // const Reset = () => {
  //   form.setFieldsValue(initialValues);
  //   setFilter(initialValues);
  // };

  const onFinish = async (filter: IReportFilter) => {
    if (currentReport) {
      if (filter.fecha && filter.fecha.length === 2) {
        filter.fecha[0] = filter.fecha[0].utcOffset(0, true);
        filter.fecha[1] = filter.fecha[1].utcOffset(0, true);
      }

      if (
        currentReport === "maquila_interna" ||
        currentReport === "maquila_externa"
      ) {
        if (filter.origen.length > 0 || filter.destino.length > 0) {
          console.log(filter.origen);
        }
      } else {
        if (filter.ciudad.length > 0 && filter.sucursalId.length === 0) {
          filter.sucursalId = branchCityOptions
            .filter((x) => filter.ciudad.includes(x.value as string))
            .flatMap((x) => (x.options ?? []).map((b) => b.value as string));
        }
      }

      await getByFilter(currentReport, filter);
      setFilter(filter);
      if (
        currentReport === "contacto" ||
        currentReport === "estudios" ||
        currentReport === "urgentes" ||
        currentReport === "empresa" ||
        currentReport === "canceladas" ||
        currentReport === "presupuestos" ||
        currentReport === "cargo" ||
        currentReport === "maquila_interna" ||
        currentReport === "maquila_externa" ||
        currentReport === "medicos-desglosado"
      ) {
        await getByChart(currentReport, filter);
      }
    }
  };

  return (
    <div className="status-container">
      <Form<IReportFilter>
        {...formItemLayout}
        form={form}
        name="report"
        initialValues={{
          sucursalId: [],
          ciudad: [],
          medicoId: [],
          estatusId: [],
          compañiaId: [],
          metodoEnvio: [],
          tipoCompañia: [],
          origen: [],
          destino: [],
          serie: [],
          tipoFecha: "",
          buscar: "",
          urgencia: [],
          grafica: false,
          detalle: false,
          fecha: [moment().utcOffset(0, true), moment().utcOffset(0, true)],
          hora: [
            moment().utcOffset(0, true).hour(0).minutes(0).utcOffset(0, true),
            moment().utcOffset(0, true).hour(23).minutes(0).utcOffset(0, true),
          ],
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col span={20}>
            <Row justify="space-between" gutter={[12, 12]}>
              {input.includes("tipoFecha") && (
                <Col span={8}>
                  <SelectInput
                    formProps={{
                      name: "tipoFecha",
                      label: "Fecha de",
                    }}
                    options={[
                      { value: "Activas", label: "Fecha de Creación" },
                      { value: "Entregadas", label: "Fecha de Entrega" },
                    ]}
                  />
                </Col>
              )}
              {input.includes("fecha") && (
                <Col span={8}>
                  <DateRangeInput
                    formProps={{ label: "Fecha", name: "fecha" }}
                    required={true}
                    disableAfterDates
                  />
                </Col>
              )}
              {input.includes("estatus") && (
                <Col span={8}>
                  <SelectInput
                    form={form}
                    formProps={{ name: "estatusId", label: "Estatus" }}
                    multiple
                    options={statusOptions}
                  />
                </Col>
              )}
              {input.includes("sucursal") &&
                currentReport !== "maquila_externa" &&
                currentReport !== "maquila_interna" && (
                  <Col span={8}>
                    <Form.Item
                      label="Sucursal"
                      className="no-error-text"
                      help=""
                    >
                      <Input.Group>
                        <Row gutter={8}>
                          <Col span={12}>
                            <SelectInput
                              form={form}
                              formProps={{
                                name: "ciudad",
                                label: "Ciudad",
                                noStyle: true,
                              }}
                              multiple
                              options={cityOptions}
                            />
                          </Col>
                          <Col span={12}>
                            <SelectInput
                              form={form}
                              formProps={{
                                name: "sucursalId",
                                label: "Sucursales",
                                noStyle: true,
                              }}
                              multiple
                              options={branchOptions}
                            />
                          </Col>
                        </Row>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                )}
              {input.includes("buscar") && (
                <Col span={8}>
                  <TextInput
                    formProps={{ name: "buscar", label: "Buscar" }}
                    autoFocus
                  />
                </Col>
              )}
              {input.includes("origen") && (
                <Col span={8}>
                  <SelectInput
                    form={form}
                    options={BranchOptions}
                    formProps={{
                      name: "origen",
                      label: "Origen",
                    }}
                    multiple
                  ></SelectInput>
                </Col>
              )}
              {input.includes("destino") && (
                <Col span={8}>
                  <SelectInput
                    form={form}
                    options={
                      currentReport === "maquila_interna"
                        ? BranchOptions
                        : ReportMaquiladorOptions
                    }
                    formProps={{
                      name: "destino",
                      label: "Destino",
                    }}
                    multiple
                  ></SelectInput>
                </Col>
              )}
              {input.includes("medico") && (
                <Col span={8}>
                  <SelectInput
                    form={form}
                    formProps={{ name: "medicoId", label: "Médico" }}
                    multiple
                    options={medicOptions}
                  />
                </Col>
              )}
              {input.includes("hora") && (
                <Col span={8}>
                  <TimeRangeInput formProps={{ label: "Hora", name: "hora" }} />
                </Col>
              )}
              {input.includes("compañia") && (
                <Col span={8}>
                  <SelectInput
                    form={form}
                    formProps={{ name: "compañiaId", label: "Compañía" }}
                    multiple
                    options={companyOptions}
                  />
                </Col>
              )}
              {input.includes("metodoEnvio") && (
                <Col span={8}>
                  <SelectInput
                    form={form}
                    formProps={{
                      name: "metodoEnvio",
                      label: "Medio de envío",
                    }}
                    multiple
                    options={sendMethodOptions}
                  />
                </Col>
              )}
              {input.includes("urgencia") && (
                <>
                  <Col span={8}>
                    <SelectInput
                      form={form}
                      formProps={{
                        name: "urgencia",
                        label: "Tipo de Urgencia",
                      }}
                      multiple
                      options={urgentOptions}
                    />
                  </Col>
                  <Col span={8}>
                    <SelectInput
                      form={form}
                      multiple
                      formProps={{ label: "Estatus", name: "estatusId" }}
                      options={studyStatusOptions}
                    />
                  </Col>
                </>
              )}
              {input.includes("serie") && (
                <Col span={8}>
                  <SelectInput
                    form={form}
                    formProps={{ name: "serie", label: "Serie de factura" }}
                    multiple
                    options={seriesOptions}
                  />
                </Col>
              )}
              {input.includes("procedencia") && (
                <Col span={8}>
                  <SelectInput
                    form={form}
                    formProps={{ name: "procedencia", label: "Procedencia" }}
                    multiple
                    options={originOptions}
                  />
                </Col>
              )}
              {input.includes("tipoCompañia") && (
                <Col span={8}>
                  <SelectInput
                    form={form}
                    formProps={{
                      name: "tipoCompañia",
                      label:
                        currentReport !== "facturas_canceladas" &&
                        currentReport !== "facturas_consaldo"
                          ? "Convenio"
                          : "Procedencia",
                    }}
                    multiple
                    options={
                      currentReport !== "facturas_canceladas" &&
                      currentReport !== "facturas_consaldo"
                        ? typeCompanyOptions
                        : typeCompanyOptionsInvoice
                    }
                  />
                </Col>
              )}
              {currentReport !== "dia" &&
                currentReport !== "condescuento" &&
                currentReport !== "adeudo" &&
                currentReport !== "facturas" &&
                currentReport !== "facturas_canceladas" &&
                currentReport !== "facturas_consaldo" &&
                currentReport !== "estadistica_estudios" && (
                  <Col span={8}>
                    <SwitchInput name="grafica" label="Gráfica" />
                  </Col>
                )}
              {currentReport === "adeudo" && (
                <Col span={8}>
                  <SwitchInput
                    name="detalle"
                    label="Detalle"
                    onChange={(value) => {
                      if (value) {
                        setDetail(true);
                      } else {
                        setDetail(false);
                      }
                      form.submit();
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Button key="new" type="primary" htmlType="submit">
              Filtrar
            </Button>
            {input.includes("limpiar") && (
              <Button key="clean" htmlType="reset">
                Limpiar
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default observer(ReportFilter);
